<template>
    <v-app>
        <v-main>
            <Header></Header>
            <v-container fluid fill-height class="BackgroundStyle-2">
                <v-layout align-center justify-center>
                    <v-flex xs12 sm6 md5 lg4>
                        <div class="Logo1" width="100%">
                            <img src="../../assets/Mww128.png" alt="My Wallet Watcher" align-center>
                        </div>
                        <v-card class="elevation-3" transition="scale-transition">
                            <v-card-text>
                                <v-form @submit="valCheck($v.user)">
                                    <div>
                                        <v-alert type="error" dismissible :value="true" v-model="showErrorMessage">
                                            {{errorMessage}}
                                        </v-alert>
                                    </div>
                                    <div>
                                        <v-text-field prepend-icon="person" name="email" label="Email" type="text" v-model="user.email"
                                                      :error-messages="emailErrors"
                                                      @input="$v.user.email.$touch()"
                                                      @blur="$v.user.email.$touch()"></v-text-field>

                                    </div>
                                    <div>
                                        <v-text-field prepend-icon="lock" name="password" label="Password" id="password" type="password" v-model="user.password"
                                                      :error-messages="passwordErrors"
                                                      @input="$v.user.password.$touch()"
                                                      @blur="$v.user.password.$touch()"></v-text-field>

                                    </div>
                                    <v-btn type="submit" color="primary" :loading="loading" rounded block v-on:click.prevent="valCheck($v.user)">
                                        Login
                                    </v-btn>
                                </v-form>

                            </v-card-text>
                            <v-card-text class="text-xs-center">
                                <a href="./PasswordRecovery" class="my-link"> Having trouble signing in? </a> <br />
                                <!--<span class="text-xs-center grey--text"> or sign in with </span>-->
                            </v-card-text>

                            <v-divider light> </v-divider>
                            <!--<div class="text-xs-center mb10 bg-grey">
                              <v-btn flat large color="primary">
                                <img src="../../assets/googleplus.png" alt="Google plus" left />
                              </v-btn>
                              <v-btn flat large color="primary">
                                <img src="../../assets/microsoft.png" alt="Google plus" left />
                              </v-btn>
                            </div>-->
                        </v-card>
                        <v-card-text>
                            <v-divider light> </v-divider>
                            <v-btn color="accent" rounded block v-on:click.prevent="$router.push({ path: '/registration' })">
                                Sign up
                            </v-btn>
                        </v-card-text>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-main>
        <!--<v-footer class="pa-3">
          <v-spacer></v-spacer>
          <div text-xs-center>
            &copy; {{ new Date().getFullYear() }} - My Wallet Watcher
          </div>
        </v-footer>-->

    </v-app>
</template>


<script>
    import { validationMixin } from 'vuelidate'
    import { required, email } from 'vuelidate/lib/validators'

    import { HTTP } from '../../api/httpCommon';
    import { auth } from '../../auth/index';

    import header from '../../components/header.vue'

    export default {
        mixins: [validationMixin],
        components: {
            'Header': header
        },
        data() {
            return {
                user: {
                    email: '',
                    password: '',
                },
                loading: false,
                showErrorMessage: false,
                errorMessage: ''
            }
        },
        computed: {
            emailErrors() {
                const errors = []
                if (!this.$v.user.email.$dirty) return errors
                !this.$v.user.email.required && errors.push('E-mail is required')
                !this.$v.user.email.email && errors.push('Must be valid e-mail')
                return errors
            },
            passwordErrors() {
                const errors = []
                if (!this.$v.user.password.$dirty) return errors
                !this.$v.user.password.required && errors.push('Password is required')
                return errors
            }
        },
        methods: {
            valCheck: function ($v) {

                $v.$touch(); //Triggers Error Messages

                if ($v.email.$invalid || $v.password.$invalid) {
                    return
                }

                this.loading = true
                this.login();
            },
            login: function () {
                this.showDismissibleAlert = true,

                    HTTP.post('AuthManagement/Login',
                        this.user
                    )
                        .then(response => {
                            auth.login(response.data.refreshToken, response.data)
                            if (response.status === 200) {
                                this.$router.push({ path: '/dashboard' })
                            }
                            else if (response.status === 401) {
                                this.errorMessage = "Email/Password Incorrect"
                                this.showErrorMessage = true
                            }
                            else if (response.status === 404) {
                                this.errorMessage = "Are you Registered? Please Resgister"
                                this.showErrorMessage = true
                            }
                            else {
                                this.errorMessage = "Something Went Wrong Please try again"
                                this.showErrorMessage = true
                            }
                            this.loading = false
                        })
                        .catch(e => {
                            try {
                                if (e.response.status === 401) {
                                    this.errorMessage = "Email/Password Incorrect"
                                    this.showErrorMessage = true
                                }
                                else if (e.response.status === 404) {
                                    this.errorMessage = "Are you Registered? Please Resgister"
                                    this.showErrorMessage = true
                                }
                                else {
                                    this.errorMessage = "Something Went Wrong Please try again"
                                    this.showErrorMessage = true
                                }
                            }
                            catch (e) {
                                this.errorMessage = "Something Went Wrong Please try again"
                                this.showErrorMessage = true
                            }

                            console.log(e)
                            this.loading = false
                        })
            }
        },
        validations: {
            user: {
                email: {
                    required,
                    email
                },
                password: {
                    required
                }
            }
        }

    }
</script>
